import React from 'react'

const FAQs = () => {
  return (
    <div className="faqs" style={{ "font-size": "1.6em", "margin": "0 auto"}}>
        <h1>FAQs</h1>
        
        <h3>What is this exactly?</h3>

        <p>
            I've always enjoyed hosting diverse groups of people! In 2018, I bought a large old house 
            to expand my abilty to host. In 2020, I married a wonderful woman and thankfully, she 
            enjoys hosting as well. 
        </p>

        <p>
            On any given weekend, we usually have a mix of friends, friends of friends, and new faces. You might
            think of it as a bed and breakfast, but with a more communal feel and a loose itinerary. You're always welcome
            to do your own thing, but we usually have a few group activities planned.
        </p>

        <p>
            On weekends we aren't using the house, we rent it out on <a href="https://www.airbnb.com/rooms/28871024" target="_blank">Airbnb</a>.
        </p>

        <h3>Who are you though?</h3>

        <p>
            My name is Jordan Manley. I'm a software engineer teaching at <a href="https://www.pursuit.org">Pursuit</a> and building <a href="https://www.codetrack.dev/">CodeTrack</a>.
            If you are interested you can learn a bit more about me <a href="https://podcasts.apple.com/us/podcast/homeschooling-and-the-unconventional-life/id1649340932?i=1000597400020">here</a> or <a href="https://podcasts.apple.com/us/podcast/liberal-arts-to-frontend-engineer-with-jordan-manley/id1660423504?i=1000618463673">here</a>. 
        </p>

        <h3>What is available for lodging? </h3>

        <p>We have 5 guest bedrooms that can be shared between 2-5 people in each one. Lodging is on a first come basis and we will do everything possible to accomodate everyone comfortably.</p>

        <h3>What do I need to bring?</h3>

        <p>You should bring anything you need for the weekend :) If you would like to bring any food or beverage to share, that would be great. But food and beverages are provided. </p>

        <p>We have linens, towels and all the basics. Games and instruments are always welcome too!</p>
    </div>
  )
}

export default FAQs