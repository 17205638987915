import { Outlet } from "react-router-dom";


import './PageLayout.scss';

const PageLayout = () => {
  return (
    <div className="pageLayout">
        <Outlet />
    </div>
  )
}

export default PageLayout;
