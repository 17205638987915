import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import './Navbar.scss';

const Navbar = () => {

  const [toggle, setToggle] = useState(false);

  return (
    <div className="navbar">
        <div className='navbar__container'>
            <div className="navbar__title" onClick={() => setToggle(false)}>
              <Link to='/'>
                The Manley's House
              </Link>
            </div>
            <div className={`navbar__items ${toggle && 'navbar__items--open'}`} onClick={() => setToggle(!toggle)}>
                <ul>
                    <Link to='/upcoming-events'>
                      <li>Upcoming Weekends</li>
                    </Link>
                    <Link to='/faqs'>
                      <li>FAQs</li>
                    </Link>
                </ul>
            </div>
            <div className='navbar__hamburger' onClick={() => setToggle(!toggle)}>
              <div className='navbar__hamburger--line'></div>
              <div className='navbar__hamburger--line'></div>
              <div className='navbar__hamburger--line'></div>
            </div>
        </div>
    </div>
  )
}

export default Navbar;
