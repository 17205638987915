import './Banner.scss';

const Banner = () => {
  return (
    <div className="banner">
        <img src='jordans_house_lite_small.jpeg' />
    </div>
  )
}

export default Banner