import Banner from "../components/banner/Banner";
import InfoBox from "../components/infoBox/InfoBox";
import RecentImages from "../components/recentImages/RecentImages";

const Home = () => {
  return (
    <div className="home">
       <div className="two-columns">
        <div>
            <Banner />
            <InfoBox />
        </div>
        
        <RecentImages />
       </div> 
    </div> 
  )
}

export default Home