import { Routes, Route } from "react-router-dom";

// layout
import PageLayout from './layouts/pageLayout/PageLayout';

// components
import Navbar from './components/navbar/Navbar';

// pages
import Home from './pages/Home';
import UpcomingWeekends from "./pages/UpcomingWeekends";
import FAQs from "./pages/FAQs";

import './App.scss';

function App() {
  return (
    <div className="app">
      <Navbar />
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<Home />} />
          <Route path="images" element={<div>Event Images</div>} />
          <Route path="upcoming-events" element={<UpcomingWeekends />} />
          <Route path="faqs" element={<FAQs />} />
          <Route path="*" element={<div>404 Nothing here!</div>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
