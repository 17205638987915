import './RecentImages.scss';

const RecentImages = () => {
  return (
    <div className="recentImages">
        <h2>Photos from Recent Events</h2>
        <ul>
            <a href="https://drive.google.com/drive/folders/1KFPdbJ1CqRw_zCHmsL-AatSvVhPHARdL?usp=sharing">
                <li>8/10 Titans of Tomorrow - Up Now!</li>
            </a>
            <a href="https://drive.google.com/drive/folders/1vfP1rJ8bklz4WO5nBBiE1lrYtA2aZNgd?usp=sharing">
                <li>8/2 Tech Mixer at the Williamsburg Hotel</li>
            </a>
        </ul>
    </div>
  )
}

export default RecentImages