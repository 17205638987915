import './InfoBox.scss';

const InfoBox = () => {
  return (
    <div className="infoBox">
        <p>
            Hi there! My wife and I host curated groups of people 
            at <a href="https://www.airbnb.com/rooms/28871024" target="_blank">our house</a> upstate a couple weekends a month. We do 
            this primarily because we love hosting and meeting new people. 
        </p>
        <p>
            We both work in the city and love to get out and explore upstate. 
        </p>
        <p>
            Come spend a weekend with us cooking and eating good food, hiking, 
            and enjoying the outdoors.
        </p>
        <p>If you're interested fill out this <a href='https://docs.google.com/forms/d/e/1FAIpQLSdiuAY2F4Z5m39h8ZskH8RgVrOHrnFvmdTLO_tum8vSs8Y9ug/viewform?usp=sf_link' target="_blank">short form</a>.</p>
    </div>
  )
}

export default InfoBox