import React from 'react'

const UpcomingWeekends = () => {
  return (
    <div className="upcomingWeekends" style={{ "font-size": "1.6em", "margin": "0 auto"}}>
        <h1>Upcoming Weekends</h1>
        <p>There are no upcoming weekends currently.</p>
        <p>If you're interested in coming in the future, fill out this <a href='https://docs.google.com/forms/d/e/1FAIpQLSdiuAY2F4Z5m39h8ZskH8RgVrOHrnFvmdTLO_tum8vSs8Y9ug/viewform?usp=sf_link' target="_blank">short form</a>.</p>

    </div>
  )
}

export default UpcomingWeekends